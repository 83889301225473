import React from "react";
import "./Selling.css";
import Gif_1 from "../../Assests/gif-1.gif";
import midi from "../../Assests/mdi_check-decagram.png";
import { FaArrowRight } from "react-icons/fa6";
import Gif_2 from "../../Assests/gif-2.gif";
import Gif_3 from '../../Assests/gif-3.gif'
const Selling = () => {
  return (
    <div className="Selling">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="Selling_image">
              <img src={Gif_1} alt="" />
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="Selling_header">
              <h1 className="Selling_title">
                Everything You Need <br></br>to Start{" "}
                <span className="text-primary">Selling Online</span>
              </h1>
            </div>
            <div className="Selling_para">
              <p>
                Shoopy is the perfect e-commerce platform for starting<br></br>{" "}
                your own online store, it offers everything you need to<br></br>{" "}
                start selling online.
              </p>
            </div>
            <hr className="Selling_hr"></hr>
            <div className="Selling_list">
              <ul className="list-unstyled">
                <li>
                  {" "}
                  <img src={midi} alt="" /> Feature-rich E-commerce Platform
                </li>
                <li>
                  <img src={midi} alt="" /> Customizable Design and Themes
                </li>
                <li>
                  <img src={midi} alt="" /> Mobile-Optimized and Responsive
                </li>
                <li>
                  <img src={midi} alt="" /> Scalable and High-Performance
                </li>
                <li>
                  <img src={midi} alt="" /> SEO-Friendly Features
                </li>
                <li>
                  <img src={midi} alt="" /> Marketing and Promotions
                </li>
              </ul>
            </div>
            <div className="Selling-button-div">
              <button className="Selling-button">
                Learn More{" "}
                <span className="Selling-button-icon">
                  <FaArrowRight />
                </span>{" "}
              </button>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="Selling_header">
              <h1 className="Selling_title">
                Everything you need <br></br>for{" "}
                <span className="text-primary">retail outlet</span>
              </h1>
            </div>
            <div className="Selling_para">
              <p>
                Shoopy is an all-in-one e-commerce platform designed for
                <br></br> retail outlets. With powerful management tools, and
                offers<br></br> a seamless shopping experience for in-store
                businesses.
              </p>
            </div>
            <hr className="Selling_hr"></hr>
            <div className="Selling_list">
              <ul className="list-unstyled">
                <li>
                  {" "}
                  <img src={midi} alt="" /> Advanced Inventory Management
                </li>
                <li>
                  <img src={midi} alt="" /> Streamlined Point of Sale (POS)
                </li>
                <li>
                  <img src={midi} alt="" /> Actionable Sales Analytics
                </li>
                <li>
                  <img src={midi} alt="" /> Seamless Multi-channel Integration
                </li>
                <li>
                  <img src={midi} alt="" /> Scalable and Adaptable Solution
                </li>
                <li>
                  <img src={midi} alt="" /> Cross-Platform Accessibility
                </li>
              </ul>
            </div>
            <div className="Selling-button-div">
              <button className="Selling-button">
                Learn More{" "}
                <span className="Selling-button-icon">
                  <FaArrowRight />
                </span>{" "}
              </button>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="Selling_image">
              <img src={Gif_2} alt="" />
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="Selling_image">
              <img src={Gif_3} alt="" />
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="Selling_header">
              <h1 className="Selling_title">
              Built for <span className="text-primary">Entrepreneur</span><br></br>and{" "}
                <span className="text-primary">partners</span>
              </h1>
            </div>
            <div className="Selling_para">
              <p>
              Shoopy is an e-commerce platform designed for<br></br>{" "}
              entrepreneurs and partners who want to launch and<br></br>{" "}
              grow a successful online business. The platform offers:
              </p>
            </div>
            <hr className="Selling_hr"></hr>
            <div className="Selling_list">
              <ul className="list-unstyled">
                <li>
                  {" "}
                  <img src={midi} alt="" /> Collaborative Ecosystem
                </li>
                <li>
                  <img src={midi} alt="" /> Business Expansion Enablement
                </li>
                <li>
                  <img src={midi} alt="" /> Entrepreneurial Spirit Embedded
                </li>
                <li>
                  <img src={midi} alt="" /> Entrepreneurial Spirit Embedded
                </li>
                <li>
                  <img src={midi} alt="" /> Innovation Catalyst
                </li>
                <li>
                  <img src={midi} alt="" /> Innovation Catalyst
                </li>
              </ul>
            </div>
            <div className="Selling-button-div">
              <button className="Selling-button">
                Learn More{" "}
                <span className="Selling-button-icon">
                  <FaArrowRight />
                </span>{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Selling;
