import React from "react";
import "./DoesWork.css";
import work_1 from '../../Assests/work-1.png'
import work_2 from '../../Assests/work-2.png'
import work_3 from '../../Assests/work-3.png'
import work_4 from '../../Assests/work-4.png'
const DoesWork = () => {
  return (
    <div className="DoesWork1">
    <div className="container mt-5">
      <div className="DoesWork">
        <div className="DoesWork_heading">
          <h1>How does it work</h1>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className=" card DoesWork_card">
                <img src={work_1} alt="" />
                <span>1. Setup your store</span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className=" card DoesWork_card">
                <img src={work_2} alt="" />
                <span>2. List your products</span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className=" card DoesWork_card">
                <img src={work_3} alt="" />
                <span>3. Drive traffic and sales</span>
            </div>
          </div>
          <div className="col-lg-3">
            <div className=" card DoesWork_card">
                <img src={work_4} alt="" />
                <span>4. Get paid</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default DoesWork;
