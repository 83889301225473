import React from 'react'
import './Forgotpassword.css'
import "bootstrap/dist/css/bootstrap.min.css";
import image_1 from "../../Assests/login.png";
import image_2 from "../../Assests/ZS HORIZONTAL LOGO - COLOR WITH BLACK.png";
import { FaUserCheck } from "react-icons/fa";
const Forgotpassword = () => {
  return (
    <div className="Loginform d-flex flex-column  align-items-center">
      <div className=" Loginform_1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
           <div className="IMAGE_RESECT">
           <img src={image_1} alt="" style={{ width: "100%" }} />
           </div>
            </div>
            <div className="col-lg-6">
              <div className="RESECT_LOGO">
                <img src={image_2} alt=""  />
              </div>
              <div>
                <h3 className="heading_reset">Reset Password</h3>
                <p>Reset your password easily</p>
              </div>
              <div className="RESECT_FORM col-12">
                <div className="mb-3 d-flex  MAIN_CONTENT">
                  <div className="d-flex justify-content-center align-items-center ">
                    <FaUserCheck className="USER_ICON" />
                  </div>
                  <div className="d-flex flex-column justify-content-start">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label MAIL_NAME"
                    >
                      Email or phone
                    </label>
                    <input
                      type="email"
                      className="MAIL"
                      placeholder="Enter Email Address"
                    />
                  </div>
                </div>
                <div  className="mt-4 RESECT_BUTTON">
                <button type="submit" class="btn btn-primary" style={{width:'100%'}}>GET OTP</button>
                </div>
              </div>
              <div className="mt-3 ">
            <p>  Already have an account?<a href="/login" className="text-decoration-none RESECT_LINK"> Login</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Forgotpassword

