import React from "react";
import "./Pricing.css";
const Pricing = () => {
  return (
    <div className="Pricing">
      <div className="container">
        <div className="Pricing_heading">
          <h1>
            Get started with a <br></br>
            <span className="span_welcome">Welcome</span> plan and <br></br>
            start <span className="span_business">business</span>
          </h1>
          <p className="Pricing_subtitle">
            Starts With Welcome and Invest your business by our Un-beatable SIP
            Plans
          </p>
        </div>
        <div className="Pricing_content">
          <div className="row">
            <div className="col-lg-3 col-sm-12">
              <div className="card card_pricing_content">
                <div className="card-body card-body_pricing_content">
                  <p className="card-title_pricing">Welcome</p>
                  <p className="card-price_pricing">
                    {" "}
                    <span>₹199</span> /3 months
                  </p>
                  <p className="card-price_para_pricing">
                    Start your dream online store for just 199 for 3 months +GST
                  </p>
                  <hr className="pricing_hr"></hr>
                  <ul className="list-unstyled  pricing_ul">
                    <li>1 Online Store.</li>
                    <li>30 Products.</li>
                    <li>10 Categories.</li>
                    <li>Unlimited Customers.</li>
                    <li>Unlimited Orders.</li>
                    <li>5 Blog limits.</li>
                    <li>10 Page Limits.</li>
                    <li>10 Discount Limits.</li>
                    <li>Advanced Reports.</li>
                    <li>Push Notification Updates.</li>
                    <li>Wishlist Features.</li>
                    <li>Themes.</li>
                    <li>Custom JS.</li>
                    <li>Cash on Delivety.</li>
                    <li>QR Code for Store.</li>
                    <li>UPI Payment Option.</li>
                    <li>Offline Payment Options.</li>
                    <li>Pickup Locations.</li>
                  </ul>

                  <button className="pricing_btn">Choose Welcome</button>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="card card_pricing_content">
                <div className="card-body card-body_pricing_content">
                  <p className="card-title_pricing">Starter Plan</p>
                  <p className="card-price_pricing">
                    {" "}
                    <span>₹999</span> /6 months
                  </p>
                  <p className="card-price_para_pricing">
                    Elevate Your E-commerce Journey with this Starter Plan at
                    Just 999/- for 6 months +GST
                  </p>
                  <hr className="pricing_hr"></hr>
                  <ul className="list-unstyled  pricing_ul">
                    <li>1 Online Store.</li>
                    <li>75 Products.</li>
                    <li>25 Categories.</li>
                    <li>Unlimited Customers.</li>
                    <li>Unlimited Orders.</li>
                    <li>25 Blog limits.</li>
                    <li>15 Page Limits.</li>
                    <li>25 Discount Limits.</li>
                    <li>1 Staff account.</li>
                    <li>100 Product Individual Reports.</li>
                    <li>No Inventory management.</li>
                    <li>Bulk Product Upload.</li>
                    <li>File Manager access.</li>
                    <li>Custom JS.</li>
                    <li>Cash on Delivety.</li>
                    <li>QR Code for Store.</li>
                    <li>Bank Transfer Integrations.</li>
                    <li>Pickup Locations.</li>
                    <li>Whihlist features.</li>
                    <li>Themes.</li>
                  </ul>

                  <button className="pricing_btn">Choose Starter Plan</button>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="card card_pricing_content">
                <div className="card-body card-body_pricing_content">
                  <p className="card-title_pricing"> Intermediate</p>
                  <p className="card-price_pricing">
                    {" "}
                    <span>₹1,699</span>/12 months
                  </p>
                  <p className="card-price_para_pricing">
                    Get Selling with our Intermediate Plan at 1699/- for 1 year
                    +GST
                  </p>
                  <hr className="pricing_hr"></hr>
                  <ul className="list-unstyled  pricing_ul">
                    <li>1 Online Store.</li>
                    <li>200 Products.</li>
                    <li>75 Categories.</li>
                    <li>Unlimited Customers.</li>
                    <li>Unlimited Orders.</li>
                    <li>unlimited Blogs.</li>
                    <li>Unlimited Pages.</li>
                    <li>50 Discount Limits.</li>
                    <li>5 Staff account.</li>
                    <li>Advanced Reports.</li>
                    <li>500 Product Individual Reports.</li>
                    <li>Inventory management.</li>
                    <li>Bulk Product Upload.</li>
                    <li>File Manager access.</li>
                    <li>File Manager access.</li>
                    <li>Notifications Updates.</li>
                    <li>Custom JS.</li>
                    <li>Cash on Delivety.</li>
                    <li>QR Code for Store.</li>
                    <li>Bank Transfer Integrations.</li>
                    <li>Razorpay Gateway Integartions.</li>
                    <li>Pickup Locations.</li>
                    <li>Wishlist Features.</li>
                    <li>Themes.</li>
                  </ul>

                  <button className="pricing_btn">Choose Intermediate</button>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="card card_pricing_content">
                <div className="card-body card-body_pricing_content">
                  <p className="card-title_pricing">Professional</p>
                  <p className="card-price_pricing">
                    {" "}
                    <span>₹2,200</span> /12 months
                  </p>
                  <p className="card-price_para_pricing">
                    Kickstart Your E-commerce Sucess at 2200 /- for 1 year +GST
                  </p>
                  <hr className="pricing_hr"></hr>
                  <ul className="list-unstyled  pricing_ul">
                    <li>1 Online Store.</li>
                    <li>500 Products.</li>
                    <li>150 Categories.</li>
                    <li>Unlimited Customers.</li>
                    <li>Unlimited Orders.</li>
                    <li>Unlimited Blogs.</li>
                    <li>Unlimited Pages.</li>
                    <li>15 Staff account.</li>
                    <li>Unlimited Product Individual</li>
                    <li>Inventory management.</li>
                    <li>Bulk Product Upload.</li>
                    <li>File Manager access.</li>
                    <li>Push Notifications Updates.</li>
                    <li>Custom domain Mapping</li>
                    <li>Custom JS</li>
                    <li>Cash on Delivery</li>
                    <li>QR Code for Store</li>
                    <li>Bank Transfer Integrations</li>
                    <li>Phonepay Gateway Integrations</li>
                    <li>Razorpay Gateway Integrations</li>
                    <li>Pickup Locations</li>
                    <li>Ippopay Gateway Integartions</li>
                    <li>Pickup Locations</li>
                    <li>Store Setup by Our Professional</li>
                    <li>Wishlist Features</li>
                    <li>Themes</li>
                  </ul>

                  <button className="pricing_btn">Choose Starter Plan</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
