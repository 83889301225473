import React, { useState } from "react";
import "./Login.css";
import { FaUserCheck } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Login() {
  const [value, setValue] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="PIXALIVE_STORE_LOGIN_FORM d-flex flex-column">
        <div className="LOGIN_FORM">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="LOGIN_IMAGE d-flex justify-content-center align-items-center">
                  <img
                    src="https://web.ztorespot.com/assets/images/login.svg"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="LOGIN_LOGO text-center">
                    <img
                      src="https://manager.ztorespot.com/uploads/staffs/images/1/2023/10/06/1696572161/ZS%20HORIZONTAL%20LOGO%20-%20COLOR%20WITH%20BLACK.png"
                      alt=""
                    />
                  </div>
                  <div className="LOGIN_WELCOME text-center fs-4 pt-3">
                    <span>Welcome Back !</span>
                  </div>
                  <div className="LOGIN_DESCRIPTION text-center fs-5 pt-1">
                    <span>Sign in to continue to Ztorespot.com.</span>
                  </div>
                  <div className="FORM_CONTENT mt-4 d-flex">
                    <div className="EMAIL_STORE d-flex">
                      <div className="USER_ICON">
                        <FaUserCheck className="USER" />
                      </div>
                      <div className="MAIL">
                        <div className="EMAIL_CONTENT">
                          <span>Email or Phone</span>
                        </div>
                        <div className="EMAIL_TEXTBOX">
                          <input
                            type="text"
                            placeholder="Enter Email Address"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="FORM_CONTENT1 mt-4 d-flex">
                    <div className="EMAIL_STORE d-flex ">
                      <div className="USER_ICON ">
                        <FaLock className="LOCK" />
                      </div>
                      <div className="MAIL d-flex align-items-center ">
                        <div className="PASSWORD">
                          <div className="EMAIL_CONTENT">
                            <span>Password</span>
                          </div>
                          <div className="EMAIL_TEXTBOX">
                            <input
                              type={passwordVisible ? "text" : "password"}
                              placeholder="Enter Password"
                            />
                          </div>
                        </div>
                        <div className="VISIBLE_ICON d-flex justify-content-center">
                          <div
                            onClick={togglePasswordVisibility}
                            style={{ cursor: "pointer" }}
                          >
                            {passwordVisible ? (
                              <FaEye className="EYE_ICON" />
                            ) : (
                              <FaEyeSlash className="EYE_ICON" />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="FORGOT_PASS d-flex justify-content-center mt-2">
                  <a href="/forgot-password">Forgot Password?</a>
                </div>
                <div className="LOGIN_BTN text-center pt-3">
                  <button type="submit">Log In</button>
                </div>
                <div className="ACCOUNT_CREATION text-center pt-3">
                  <span>Create Account?</span>
                  <a href="/register">Register</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="TERMS_CONDITIONS">
          <a href="/terms-and-conditions">Terms and Condition </a>|
          <a href="/return-refund-policy"> Return/Refund Policy</a>
        </div>
      </div>
    </>
  );
}

export default Login;
