import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import {
  Link as ScrollLink,
  animateScroll as scroll,
  scroller,
} from "react-scroll";
import "./Header.css";
import store from '../../Assests/Frame.png.jpg'
import { TiThMenu, TiTimes } from "react-icons/ti";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleScrollLinkClick = (to) => {
    navigate(`/#${to}`);
  };

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const id = hash.replace("#", "");
      scroller.scrollTo(id, {
        duration: 200,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [location]);

  return (
  
      <div className="header-container">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2 col-6 ">
              <div className="logo_image mt-3 ">
                <RouterLink to="/">
                  <img src={store} alt="logo" className="image-fluid" />
                </RouterLink>
              </div>
            </div>
            <div className="col-lg-7 col-6 d-lg-block d-none">
              <ul className="list-unstyled d-flex justify-content-evenly align-items-center header_contents">
                <li className="headitems">
                  <RouterLink
                    to="/"
                    className="text-decoration-none text-dark"
                    onClick={closeMenu}
                  >
                    Home
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    to="/pricing"
                    className="text-decoration-none text-dark"
                  >
                    Pricing
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    to="/help-videos"
                    className="text-decoration-none text-dark"
                    onClick={closeMenu}
                  >
                    Help Videos
                  </RouterLink>
                </li>
                <li>
                  <RouterLink
                    to="/contact-us"
                    className="text-decoration-none text-dark"
                    onClick={closeMenu}
                  >
                    Contact Us
                  </RouterLink>
                </li>
              </ul>
            </div>
            <div className="col-6 d-lg-none text-end">
              <button className="toggle-btn" onClick={toggleMenu}>
                {isOpen ? (
                  <TiTimes className="menu_button" />
                ) : (
                  <TiThMenu className="menu_button" />
                )}
              </button>
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="mobile-menu">
            <ul className="list-unstyled header_contents">
              <li>
                <RouterLink
                  to="/"
                  onClick={closeMenu}
                  className="text-decoration-none text-white"
                >
                  Home
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  to="/pricing"
                  className="text-decoration-none text-white"
                >
                  Pricing
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  to="/help-videos"
                  onClick={closeMenu}
                  className="text-decoration-none text-white"
                >
                  Help Videos
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  to="/contact-us"
                  onClick={closeMenu}
                  className="text-decoration-none text-white"
                >
                  Contact Us
                </RouterLink>
              </li>
            </ul>
          </div> 
        )}
     </div>
   
  );
};

export default Header;
