import React from "react";
import "./Privacy.css";

const Privacy = () => {
  return (
    <div className="privacy">
      <div className="container">
        <div className="privacy_heading">
          <h1>Privacy Policy</h1>
        </div>
        <div className="privacy_content">
          <p>
            In India, data protection laws for children are governed primarily
            by the Information Technology (Reasonable Security Practices and
            Procedures and Sensitive Personal Data or Information) Rules, 2011,
            and the Information Technology (Intermediary Guidelines) Rules,
            2011. These laws focus on protecting personal data and do not
            specifically target children as a distinct category. However,
            businesses and websites that collect and process personal data,
            including that of children, are required to adhere to these rules.
            Below is a general privacy policy that can be adapted for compliance
            with Indian data protection laws when dealing with children's data:
          </p>
          <p>Last Updated: 03.10.2023</p>
          <p>
           <span className="privacy_title">Pixalive Store Solution Private Limited </span>("we," "our," or
            "us") operates the website ztorespot.com (the "Site"). We are
            committed to protecting your privacy and ensuring the security of
            your personal information. This Privacy Policy outlines our
            practices concerning the collection, use, and sharing of your
            personal information when you visit our Site.
          </p>
          <p>
            Please read this Privacy Policy carefully to understand how we
            handle your personal information. By accessing or using the Site,
            you consent to the practices described in this Privacy Policy.
          </p>
          <p><span className="privacy_title">Information We Collect</span></p>
          <p>
            We may collect the following types of personal information from you:
          </p>
          <p>
            <span className="privacy_title">Information You Provide:</span> We may collect information that you provide
            to us directly, such as your name, email address, postal address,
            phone number, and any other information you voluntarily submit
            through our Site.
          </p>
          <p>
            <span className="privacy_title">Automatically Collected Information:</span> When you visit our Site, we may
            automatically collect certain information about your device,
            including your IP address, browser type, operating system, and other
            technical information.
          </p>
          <p>
            <span className="privacy_title">Cookies and Tracking Technologies:</span> We use cookies and similar
            tracking technologies to collect information about your interactions
            with our Site. These technologies may collect information about your
            browsing behavior and preferences.
          </p>
          <p><span className="privacy_title">Children's Privacy</span></p>
          <p>
            Ztorespot is committed to not intentionally gathering or requesting
            personal data from individuals under the age of 13. Our platform and
            its content are not intended for individuals under the age of 13. If
            we become aware that we have inadvertently collected personal
            information from an individual under 18 years old without proper
            parental consent verification, we will promptly remove that
            information from our records. If you suspect that we may have
            information about an individual under 18, We encourage to please
            contact us immediately via Email:
            <a>
              <span className="text-primary">contact@pixalive.me</span>
            </a>
          </p>
          <p><span className="privacy_title">How We Use Your Information</span></p>
          <p>
            We may use your personal information for the following purposes:
          </p>
          <p>
            Providing Services: To provide you with the services you request on
            our Site.
          </p>
          <p>
            Communication: To communicate with you, respond to your inquiries,
            and send you information about our products and services.
          </p>
          <p>
            Analytics: To analyze and improve the functionality and performance
            of our Site, including understanding how visitors use our Site.
          </p>
          <p>
            Legal Compliance: To comply with applicable laws and regulations.
          </p>
          <p><span className="privacy_title">Information Sharing</span></p>
          <p>
            We may share your personal information with third parties under the
            following circumstances:
          </p>
          <p>
            <span className="privacy_title">Service Providers:</span> We may share your information with third-party
            service providers who assist us in providing services, such as web
            hosting, analytics, and email communication.
          </p>
          <p>
            <span className="privacy_title">Legal Requirements:</span> We may disclose your information if required by
            law or in response to a subpoena, court order, or other legal
            process.
          </p>
          <p>
            <span className="privacy_title">Business Transfers:</span> In the event of a merger, acquisition, or sale
            of all or part of our company, your information may be transferred
            as part of the transaction.
          </p>
          <p><span className="privacy_title">Security</span></p>
          <p>
            We take reasonable measures to protect your personal information
            from unauthorized access, disclosure, or alteration. However, no
            data transmission over the internet is entirely secure, and we
            cannot guarantee the security of your information.
          </p>
          <p><span className="privacy_title">Your Choices</span></p>
          <p>
            You may have certain rights regarding your personal information. You
            can request access to, correction, deletion, or restriction of your
            personal information by contacting us at [contact email]. You may
            also have the right to object to the processing of your personal
            information.
          </p>
          <p><span className="privacy_title">Changes to this Privacy Policy</span></p>
          <p>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or for other operational, legal, or
            regulatory reasons. Please review this Privacy Policy periodically
            to stay informed about how we are protecting your personal
            information. Also we will let you know about our new policies change
            via Email.
          </p>
          <p><span className="privacy_title">Contact Us</span></p>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at:
          </p>
          <p><span className="privacy_title">Company Name:</span> Pixalive Stores Solution Private Limited.</p>
          <p>
           <span className="privacy_title"> Email:</span>
            <a>
              <span className="text-primary">contact@pixalive.me</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
