import React from "react";
import "./RefundPolicy.css";

const RefundPolicy = () => {
  return (
    <div className="RefundPolicy">
      <div className="container">
        <div className="RefundPolicy_heading">
          <h1>Refund Policy</h1>
        </div>
        <div className="RefundPolicy_content">
          <p>
            Effective Date: 03.10.2023<br></br>
            Thank you for choosing Ztorespot™ to enhance your business. We are
            dedicated to providing you with top-notch services. Please carefully
            review our Refund & Return Policy to understand the procedures and
            guidelines in the event that you need to request a refund for our
            services.
          </p>
          <span className="Refund_title">1. Refunds for Non-Delivery of Services</span>
          <p>
            We understand that unforeseen circumstances can arise. If we fail to
            deliver the purchased services (referred to as "Goods" in this
            policy) as per the agreed terms and within the specified time frame,
            you may be eligible for a refund.
          </p>
          <span className="Refund_title">1.1 Eligibility for Refund</span>
          <p>
            To be eligible for a refund due to non-delivery of services, the
            following conditions must be met:
          </p>
          <p>
            The request for a refund must be initiated within 14 working days
            from the date when the services should have been delivered as per
            the agreement.
          </p>
          <span className="Refund_title">1.2 Refund Process</span>
          <p>
            To request a refund for non-delivery of services, please follow
            these steps:
          </p>
          <p>
            Contact our customer support team via [Customer Support Email/Phone]
            within the specified time frame. Provide details of the services not
            delivered and any relevant documentation or communication regarding
            the agreement. Our team will review your request and investigate the
            issue.
          </p>
          <span className="Refund_title">1.3 Refund Outcome</span>
          <p>
            If our investigation confirms non-delivery of services within the
            agreed-upon terms, we will initiate a refund to your original
            payment method. The refund will be processed within 14 business days
            from the date your request is approved.
          </p>
          <span className="Refund_title">2. Returns for Services Received</span>
          <p>
            In the event that you receive the services (Goods) and are
            dissatisfied or believe they do not meet the agreed-upon
            specifications, we allow for returns and refund requests under
            certain conditions.
          </p>
          <span className="Refund_title">2.1 Eligibility for Return & Refund</span>
          <p>
            To be eligible for a return and refund for services received, the
            following conditions must be met:
          </p>
          <p>
            The refund application should be submitted within 7 days from the
            date you receive the services.<br></br>
            The services provided do not adhere to the agreed-upon
            specifications or quality standards.
          </p>
          <span className="Refund_title">2.2 Return & Refund Process</span>
          <p>
            To initiate a return and refund for services received, please follow
            these steps:
          </p>
          <p>
            Contact our customer support team via [Customer Support Email/Phone]
            within 7 days of receiving the services. Provide detailed
            information on why you are requesting a return and any relevant
            documentation that supports your claim. Our team will review your
            request and assess the services provided against the agreed-upon
            specifications.
          </p>
          <span className="Refund_title">2.3 Refund Outcome</span>
          <p>
            If your return and refund request is approved after our evaluation,
            we will process a refund to your original payment method. The refund
            will be processed within 14 business days from the date your request
            is approved.
          </p>
          <span className="Refund_title">3. Shipping Policy</span>
          <p>
            At Ztorespot, we offer a service-based approach to shipping. Our
            shipping and delivery occur after successful payment has been made.
            In the event of any issues, we strive to resolve them promptly,
            ensuring that your purchased order (Goods) is delivered within 0-3
            working days. Your satisfaction and timely delivery are our
            priorities. It's completely free.
          </p>
          <span className="Refund_title">4. Contact Us</span>
          <p>
            If you have any questions or concerns regarding our Refund & Return
            Policy or any aspect of our services, please do not hesitate to
            reach out to our customer support team at [Customer Support
            Email/Phone]. We are here to assist you and ensure your satisfaction
            with our E-commerce Platform Service.
          </p>
          <span className="Refund_title">Ztorespot.com Operates </span>
          <p>Pixalive Store Solution Private limited.</p>
          <p>
            <span className="Refund_title">Office Address:</span>Pixalive 2nd Phase, Third Floor, 35/2,
            Hosur Rd, Konappana Agrahara, Electronic City, Bengaluru, Karnataka
            560100
          </p>
          <p>
            <span className="Refund_title"> Email Address: </span>
            <a>
              <span className="text-primary">contact@pixalive.me</span>
            </a>
            <span className="Refund_title">Mobile No:8778584566</span>
          </p>
          <p>Website URL: https://ztorespot.com/</p>
          <p>
            Please note that this Refund & Return Policy may be subject to
            updates and changes. We recommend reviewing this policy periodically
            to stay informed of any modifications.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
