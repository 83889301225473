import React from "react";
import "./Faq.css";
const Faq = () => {
  return (
    <div className="faq">
      <div className="container">
        <div className="faq_heading text-center">
          <h1>
            <p>
              Frequently Asked{" "}
              <span className="faq_heading_span">Questions</span>
            </p>
          </h1>
          <p className="FAQ_subtitle">
            Search for the questions that are frequently asked about
            Ztorespot.com
          </p>
        </div>
        <div className="faq_content">
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <p className="faq_question">What is Ztorespot.com</p>
              <p className="faq_answer_1 ">
                Ztorespot.com is a DIY platform that allows anyone to create
                their dream online store within 5 minutes. It's an ideal
                solution for small and medium-scale business owners, including
                those running offline stores such as grocery shops, apparel
                stores, snack vendors, restaurants, homemade business owners,
                and more.
              </p>
            </div>
            <div className="col-lg-4 col-sm-12">
              <p className="faq_question">
                How is Ztorespot.com different from other e-commerce platforms?
              </p>
              <p className="faq_answer">
                Ztorespot.com stands out for its simplicity. Unlike other
                platforms that may be complex, Ztorespot.com is designed to be
                easy to understand and use. There are no intricate processes,
                making it perfect for users with basic computer or mobile
                operating knowledge.
              </p>
            </div>
            <div className="col-lg-4 col-sm-12">
              <p className="faq_question">
                Is Ztorespot.com affordable compared to other e-commerce
                platforms?
              </p>
              <p className="faq_answer">
                Yes, the pricing for Ztorespot.com is more budget-friendly
                compared to other e-commerce platforms like Shopify, Dukaan,
                Wix, and Woocommerce. We believe in providing cost-effective
                solutions to cater to the needs of small and medium-sized
                businesses.
              </p>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-4 col-sm-12">
              <p className="faq_question">
                What types of businesses is Ztorespot.com suitable for?
              </p>
              <p className="faq_answer">
                Ztorespot.com is perfect for a variety of businesses, including
                grocery stores, apparel shops, snack vendors, restaurants, and
                homemade business owners. It caters to the needs of small and
                medium-scale business owners looking to establish their online
                presence
              </p>
            </div>
            <div className="col-lg-4 col-sm-12">
              <p className="faq_question">
                Do I need coding skills to use Ztorespot.com?
              </p>
              <p className="faq_answer_1">
                No, Ztorespot.com is a no-code platform. Basic computer or
                mobile operating knowledge is sufficient to run your store. The
                platform is designed to be user-friendly, allowing anyone to set
                up and manage their online store without the need for coding
                skills.
              </p>
            </div>
            <div className="col-lg-4 col-sm-12">
              <p className="faq_question">Is there a free trial available?</p>
              <p className="faq_answer_1">
                While there isn't a traditional free trial, Ztorespot.com offers
                an introductory period of 3 months at very affordable price at
                Rs.199. This gives you the opportunity to explore and use the
                platform. If you find it suitable for your needs, you can then
                choose to upgrade to one of our SIP plans, including Starter,
                Intermediate, and Professional plans.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
