import React from "react";
import "./Footer.css";
import zstore from "../../Assests/Frame.png.jpg";
import { Link } from "react-router-dom";
import india from "../../Assests/india (1).png";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
const Footer = () => {
  return (
    <>
      <div className="footer_container">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-3 col-sm-12 zstore1 ">
              <img src={zstore} alt=""/>
            </div>
            <hr className="Footer_hr"></hr>
            <div className="col-lg-1 col-sm-12"></div>
            <div className="col-lg-8">
              <ul className="d-flex  flex-wrap justify-content-end align-items-center  list-unstyled footer_contents ">
                <Link
                  to="/terms-and-conditions"
                  className="text-decoration-none"
                >
                  <li>Terms and Conditions</li>
                </Link>
                <Link to="/privacy" className="text-decoration-none">
                  <li>Privacy Policies </li>
                </Link>
                <Link
                  to="/return-refund-policy"
                  className="text-decoration-none"
                >
                  <li>Refund Policies</li>
                </Link>
              </ul>
            </div>
          </div>
          <div className="row ">
            <div className="Footer_down d-flex justify-content-evenly">
              <div className="col-lg-5 col-sm-5 ">
                <span className="F">
                  Pixalive Store Solution Private Limited. @ All Rights
                  Reserved, 2024.
                </span>
              </div>
              <div className="col-lg-7 col-sm-7 mb-5">
                <div className="Footer_contents_one">
                  <div>
                    <div className="footer_social_icons">
                      <span>
                        <FaFacebook className="icons_footer" />
                      </span>
                      <span>
                        <FaTwitter className="icons_footer" />
                      </span>
                      <span>
                        <FaInstagram className="icons_footer" />
                      </span>
                      <span>
                        <FaYoutube className="icons_footer" />
                      </span>
                    </div>
                  </div>

                  <span className="MadeIn">MadeIn</span>
                  <img src={india} alt="" className="india" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
