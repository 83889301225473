import React from "react";
import "./Themedecision.css";
import faeture1 from "../../Assests/feature-1.png";
import faeture2 from "../../Assests/feature-2.png";
const Themedecision = () => {
  return (
    <>
      <div className="Themedecision">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12 ">
              <div className="Themedecision_-content">
                <span className="Themedecision_span">
                  Apply your own <span className="text-primary">theming</span>{" "}
                  decisions.
                </span>{" "}
                <p className="Themedecision_para_content">
                  Unleash your creativity with ZtoreSpot's theme customization
                  feature! 🎨 Whether you prefer bold and vibrant colors 🌈 or a
                  sleek and minimalist look, ZtoreSpot has got you covered. With
                  just a few clicks, you can easily customize the default themes
                  to suit your style and preferences. So why settle for a boring
                  default theme when you can make it uniquely yours? Try
                  ZtoreSpot today and start creating stunning designs that truly
                  reflect your personality and brand! 💻🎉
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 ">
              <div className="Themedecision_image">
                <img src={faeture1} alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 ">
              <div className="Themedecision_image_1">
                <img src={faeture2} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 ">
              <div className="Themedecision_content_1">
                <span className="Themedecision_span">
                  Export your store{" "}
                  <span className="text-success">reports</span> in a second
                </span>
                <p className="Themedecision_para_content">
                  📊 Keep track of your progress and stay on top of your goals
                  with ZtoreSpot's powerful reporting feature! Whether you need
                  to generate yearly, monthly, or weekly reports, ZtoreSpot
                  makes it easy to check and export the data you need. With just
                  a few clicks, you can access detailed insights into your
                  performance and identify areas where you can improve. So why
                  waste time manually compiling reports when you can let
                  ZtoreSpot do the heavy lifting for you? Try it out today and
                  take your productivity to the next level! 💪🚀
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Themedecision;
