import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonails.css";
import test_logo_1 from "../../Assests/testi-logo-1.png";
import Writer_1 from "../../Assests/writer-1.png";
import test_logo_2 from "../../Assests/testi-logo-2.png";
import Writer_2 from "../../Assests/writer-2.png";
import test_logo_3 from "../../Assests/testi-logo-3.png";
import Writer_3 from "../../Assests/writer-3.png";

function SimpleSlider() {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="slider-container">
      <div className="Testimonails">
        <div className="container">
          <div className="Testimonails_heading">
            <h1>Testimonails</h1>
          </div>
          <Slider {...settings}>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 tata_trust d-flex flex-column">
                  <img src={test_logo_1} alt="" className="tata_trust_logo" />
                  <span className="tata_trust_para">
                    Very professional, polite and customer centric team, always
                    ready<br></br>
                    to go the extra mile.
                  </span>
                  <div className="tata_trust_writer d-flex flex-row ">
                    <img
                      src={Writer_1}
                      alt=""
                      className="tata_trust_writer_img rounded-circle"
                    />
                    <div className="tata_trust_writer_content d-flex flex-column  ">
                      <span>Dr Devsen Kruthiventi</span>
                      <span>Chief Technology Officer, Tata Trusts</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 tata_trust_column_2">
                  <span className="text-danger">
                    "After evaluating multiple products in the market we zeroed
                    in on their tool which is a robust customizable solution
                  </span>
                  <br></br>
                  <br></br>
                  <span className="text-danger">
                    It was a pleasure working with Nirmal and his team, who were
                    able to deliver a high quality product within a very tight
                    deadline.
                  </span>
                  <br></br> <br></br>
                  <span className="text-danger">
                    True to their name , OutDo Inc do outdo Themselves!!!
                  </span>
                  <br></br>
                  <br></br>
                  <span className="text-danger">
                    Look forward to working with this team in more engagements
                    in the furtute."
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 tata_trust d-flex flex-column">
                    <img
                      src={test_logo_2}
                      alt=""
                      className="tata_trust_logo_1"
                    />
                    <span className="tata_trust_para">
                      I really do believe that OutDo goes the <br></br>extra mileto
                      understand a customers <br></br>requirement and delivers a quality<br></br>
                      product.
                    </span>
                    <div className="tata_trust_writer d-flex flex-row ">
                      <img
                        src={Writer_2}
                        alt=""
                        className="tata_trust_writer_img rounded-circle"
                      />
                      <div className="tata_trust_writer_content d-flex flex-column  ">
                        <span>Arun Nair</span>
                        <span>Partner, Your Design</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 tata_trust_column_2">
                    <span className="text-primary">
                      "Going with Outdo inc for our website deveopment was
                      perhaps the best decision were taken this year. I did meet
                      a bunch of other developers but my first meeting with
                      Nirmal for Outdo virtually seal the deal. It was a
                      pleasure working with Nirmal and his team who were able to
                      deliver a high quality product within a very tight
                      deadline.
                    </span>
                    {/* <br></br>
                    <br></br>
                    <span className="text-primary">
                      One of the best aspects of this relation has been the
                      attention to deal and the patience given to each other of
                      our queries/requirements or changes.
                    </span>
                    <br></br> <br></br>
                    <span className="text-primary">
                      The unwavering thought process and clarity resulted in a
                      clean and functional website which many have praised.
                      Nirmal himself was invested in each part of the process
                      and meet with us on various occasions to understand how to
                      do things better. I'm not sure how many people would do
                      the same.{" "}
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 tata_trust d-flex flex-column">
                    <img
                      src={test_logo_3}
                      alt=""
                      className="tata_trust_logo_2"
                    />
                    <span className="tata_trust_para">
                      I would recommend Outdo cart to anyone thinking to start an online store!
                    </span>
                    <div className="tata_trust_writer d-flex flex-row ">
                      <img
                        src={Writer_3}
                        alt=""
                        className="tata_trust_writer_img rounded-circle"
                      />
                      <div className="tata_trust_writer_content d-flex flex-column  ">
                        <span>Thrilok Chakravarthy</span>
                        <span>Founder and CEO, Artkradle</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 tata_trust_column_2">
                    <span className="text-danger">
                      "I wanted an E-commerce solution which can be customized
                      as per my needs and I met Nirmal at that time for the
                      same.
                    </span>
                    <br></br>
                    <br></br>
                    <span className="text-danger">
                      He gave me a demo of Outdo Cart and by the end of the demo
                      I was sure that I want to go with it. Clean and simple
                      interface for all users, customizable, custom built
                      themes, and seamless integration with other third party
                      apps makes it a winner.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default SimpleSlider;
