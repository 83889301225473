import React from "react";
import "./TopBrands.css";
import c_1 from "../../Assests/1c.png";
import c_2 from "../../Assests/2c.png";
import c_3 from "../../Assests/3c.png";
import c_4 from "../../Assests/4c.png";
import c_5 from "../../Assests/5c.png";
import c_6 from "../../Assests/6c.png";
import c_7 from "../../Assests/7c.png";
import c_8 from "../../Assests/8c.png";
import c_9 from "../../Assests/9c.png";
import c_10 from "../../Assests/10c.png";
const TopBrands = () => {
  return (
    <div className="TopBrands">
      <div className="container">
        <div className="TopBrands_heading">
          <h1>Top Brands Believe in us</h1>
        </div>
        <div className="row">
          <div className="col-lg-12 col-12">
            <div className="top_brangs_1">
              <img src={c_1} alt="" />
              <img src={c_2} alt="" />
              <img src={c_3} alt="" />
              <img src={c_4} alt="" />
              <img src={c_5} alt="" />
              <img src={c_6} alt="brandlogo" />
              <img src={c_7} alt="brandlogo" />
              <img src={c_8} alt="brandlogo" />
              <img src={c_9} alt="brandlogo" />
              <img src={c_10} alt="brandlogo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBrands;
