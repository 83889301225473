import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Landingpage from "./Pages/Landingpage/Landingpage";
import Register from "./Pages/Register/Register";
import Allpages from "./Pages/Fullpage/Allpages";
import Terms from "./Pages/Terms/Terms";
import Privacy from "./Pages/Privacy/Privacy";
import RefundPolicy from "./Pages/RefundPolicy/RefundPolicy";
import Forgotpassword from "./Pages/Forgotpassword/Forgotpassword";
import Login from "./Pages/Login/Login";
import Contactus from "./Pages/Contactus/Contactus";
import Pricing from "./Pages/Pricing/Pricing";

function App() {
  const routes = createBrowserRouter([
    {
      path: "/",
      element: <Landingpage />,
      children: [
        {
          path: "/",
          element: <Allpages />,
        },
        {
          path: "/pricing",
          element: <Pricing />,
        },
        {
          path: "/terms-and-conditions",
          element: <Terms />,
        },
        {
          path: "/privacy",
          element: <Privacy />,
        },
        {
          path: "/return-refund-policy",
          element: <RefundPolicy />,
        },
        {
          path: "/contact-us",
          element: <Contactus />,
        },
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/forgot-password",
      element: <Forgotpassword />,
    },
    
  ]);

  return (
    <>
      <div className="App">
        <RouterProvider router={routes} />
      </div>
    </>
  );
}

export default App;
