import React from "react";
import Home from "../Home/Home";
import Faq from "../Faq/Faq";
import Pricing from "../Pricing/Pricing";
import WeOffer from "../WeOffer/WeOffer";
import Themedecision from "../Themedecision/Themedecision";
import Templates from "../Templates/Templates";
import Selling from "../Selling/Selling";
import DoesWork from "../DoesWork/DoesWork";
import OnlineStore from "../OnlineStore/OnlineStore";
import TopBrands from "../TopBrands/TopBrands";
import Testimonails from "../Testimonails/Testimonails";


const Allpages = () => {
  return (
    <>
      <div className="allpages">
        <div id="home">
          <Home />
        </div>
        <div>
          <TopBrands />
        </div>
        <div>
          <DoesWork />
        </div>
        <div>
          <Testimonails/>
        </div>
        <div>
          <WeOffer />
        </div>
        <div id="templates">
          <Templates />
        </div>
        <div>
          <Selling />
        </div>
        <div>
          <Themedecision />
        </div>
        <div id="faq">
          <Faq />
        </div>
        <div>
          <OnlineStore />
        </div>
        
      </div>
    </>
  );
};

export default Allpages;
