import React from "react";
import "./Register.css";
import { FaUserCheck } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
function Register() {
  return (
    <div>
      <div className="PIXALIVE_STORE_REG_FORM d-flex flex-column">
        <div className="container">
          <div className="REGISTER_FORM">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="REGISTER_IMAGE d-flex justify-content-center align-items-center">
                  <img
                    src="https://web.ztorespot.com/assets/images/register.svg"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="REGISTER_LOGO text-center">
                    <img
                      src="https://manager.ztorespot.com/uploads/staffs/images/1/2023/10/06/1696572161/ZS%20HORIZONTAL%20LOGO%20-%20COLOR%20WITH%20BLACK.png"
                      alt=""
                    />
                  </div>
                  <div className="REGISTER_WELCOME text-center fs-4 pt-3">
                    <span>Create an account !</span>
                  </div>
                  <div className="REGISTER_DESCRIPTION text-center fs-5 pt-1">
                    <span>Register to continue to Ztorespot.com.</span>
                  </div>
                  <div className="REG_FORM_CONTENT mt-4 d-flex">
                    <div className="USER_NAME d-flex">
                      <div className="USR_ICON">
                        <FaUserCheck className="USER" />
                      </div>
                      <div className="USR_NAME">
                        <div className="NAME_CONTENT">
                          <span>Name</span>
                        </div>
                        <div className="NAME_TEXTBOX">
                          <input type="text" placeholder="Enter your name" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="REG_FORM_CONTENT1 mt-4 d-flex">
                    <div className="EMAIL_STORE d-flex gap-3">
                      <div className="PHN_ICON">
                        <FaPhoneAlt className="PHONE" />
                      </div>
                      <div className="MAIL d-flex">
                        <div className="PHN_NUMBER">
                          <div className="PHN_CONTENT">
                            <span>Phone Number</span>
                          </div>
                          <div className="USR_PHN_NUM_TXTBOX">
                            <input type="text" placeholder="Phone Number" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="GET_OTP_BTN text-center pt-3">
                  <button type="submit">Get OTP</button>
                </div>
                <div className="REG_HAVE_ACCOUNT text-center pt-3">
                  <span>Already have an account?</span>
                  <a href="/login">Login</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="TERMS_CONDITIONS">
          <a href="/terms-and-conditions">Terms and Condition </a>|
          <a href="/return-refund-policy"> Return/Refund Policy</a>
        </div>
      </div>
    </div>
  );
}

export default Register;
