import React from "react";
import "./WeOffer.css";
import easy from "../../Assests/easy-to-use (1).png";
import nocode from "../../Assests/no-code.png";
import additional from "../../Assests/no-app.png";
import trans from "../../Assests/no-fee.png";
import manage from "../../Assests/manage-inventory.png";
import orders from "../../Assests/manage-orders.png";
import skills from '../../Assests/no-skills.png'
import staff from '../../Assests/staffs.png'
import templates from '../../Assests/templates.png'
import secure from '../../Assests/secure-payment.png'
import customer from '../../Assests/customer-based-report.png'
import sms from '../../Assests/free-sms-otp.png'
const WeOffer = () => {
  return (
    <div className="WeOffer">
      <div className="container">
        <div className="WeOffer_heading">
          <p className="WeOffer_title">
            What <span className="we">we</span>{" "}
            <span className="offer">Offer</span>
          </p>
          <p className="WeOffer_subtitle">
            A fully featured eCommerce Platform
          </p>
        </div>
        <div className="WeOffer_content">
          <div className="row">
            <div className="col-lg-12 col-md-6 col-sm-6">
              <div className="WeOffer_card ">
                <div className="card_1 col-lg-2 col-6">
                  <img src={easy} alt="" />
                  <span>Easy to use and efficient</span>
                </div>
                <div className="card_2 col-lg-2 col-6">
                  <img src={nocode} alt="" />
                  <span>No Code Platform</span>
                </div>
                <div className="card_3 col-lg-2 col-6 ">
                  <img src={additional} alt="" />
                  <span>No Additional Apps Requires</span>
                </div>
                <div className="card_4 col-lg-2 col-6 ">
                  <img src={trans} alt="" />
                  <span>No Transaction Fees</span>
                </div>
                <div className="card_5 col-lg-2 col-6 ">
                  <img src={manage} alt="" />
                  <span>Manage Inventory</span>
                </div>
                <div className="card_6 col-lg-2 col-6 ">
                  <img src={orders} alt="" />
                  <span>Manage Store Orders</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="WeOffer_content">
          <div className="row">
            <div className="col-lg-12 col-md-6 col-6">
              <div className=" WeOffer_card_1">
                <div className="card_1 col-lg-2 col-6 ">
                  <img src={skills} alt="" />
                  <span>No Technical Skills Requires</span>
                </div>
                <div className="card_2 col-lg-2 col-6">
                  <img src={staff} alt="" />
                  <span>Multiple Staff Accounts</span>
                </div>
                <div className="card_3 col-lg-2 col-6 ">
                  <img src={templates} alt="" />
                  <span>Multiple Templates</span>
                </div>
                <div className="card_4 col-lg-2 col-6">
                  <img src={secure} alt="" />
                  <span>Secure Payments</span>
                </div>
                <div className="card_5 col-lg-2 col-6">
                  <img src={customer} alt="" />
                  <span>Customer Based Reports</span>
                </div>
                <div className="card_6 col-lg-2 col-6 ">
                  <img src={sms} alt="" />
                  <span>Reliable free SMS OTP</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeOffer;
