import React from "react";
import "./Terms.css";

const Terms = () => {
  return (
    <div className="TermsandConditions">
      <div className="container">
        <div className="terms_heading">
          <h1>Terms and Conditions</h1>
        </div>
        <div className="terms_content">
          <p className="terms_title">NORMS TO KNOW:</p>
          <p className="terms_para">
            These norms and terms are officially Authorized for merchants,
            buyers and consumers who all are making use of this Ztorespot.com ™
            e-commerce platform. Please carefully read all these queries before
            you use.
          </p>
          <p className="terms_title">MERCHANS PRINCIPLES TO FOLLOW: </p>
          <p className="terms_para">
            After proper registration Merchant plays a virtual role here. The
            list of products must be legal, don't allow it sale any banned and
            unauthorized products restricted by the Government policies. If it
            is find out by us, immediately we will intimate you after that we
            will suspend or delete your account strictly or otherwise prohibited
            for sale under applicable Law.<br></br>
            The merchants may list and provide all the details of the products
            with original and clear footage. Before you upload your products
            check out the stock availability whether it is possible to sale your
            customers are not. The merchants must clearly aware of your products
            with all the certified details if we ask for any queries the
            merchant ready to proclaim particular details. A merchant uses this
            platform in proper guidelines we heartily welcome them and give
            successful fulfillment on their service.
          </p>
          <p className="terms_title">CONSUMERS PRINCIPLES TO FOLLOW:</p>
          <p className="terms_para">
            Consumers (Buyers) who all are use this platform for your needs
            kindly check out the products before you ready to buy any products
            through our platforms, there is any enquiries and defaults you find
            directly deal with particular merchant who sales the product
            remember that the company is not involve in any concern matters.
            Consumers suggested to pay your bills with proper guidelines given
            on this Website.
          </p>
          <p className="terms_title">Refunds,Cancellation and Termination</p>
          <p className="terms_para">
            Refunds will not be granted for untimely cancellations, regardless
            of the reasons thereof. To halt your payments for the services
            provided via our Platform, you must discontinue access and
            utilization of the Platform. We retain the authority to modify or
            deactivate a Merchant account's username and/or any other Merchant
            Store at our discretion. This procedure applies when the
            username/Merchant account/Merchant Store fails to adhere to our
            established standards and policies.
          </p>

          <p className="terms_title">Transactions</p>
          <p className="terms_para">
            We maintain the prerogative to annul any services at our exclusive
            discretion. Nevertheless, we ensure that any cancellation of a
            subscription is communicated promptly to the relevant party.
            Refunds, if applicable, will be processed in a timely manner.
          </p>
          <p className="terms_title">GENERAL INFORMATION:</p>
          <p className="terms_para">
            This platform is built for the people who are selling their products
            and consumers who are receiving the product we are neutrally
            mediating and welcoming your services , requested both of them to
            use it with following terms and conditions . The merchants and
            consumers do not follow our terms and conditions immediately the
            company can have all the possibilities to quit your services Thank
            you.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
