import React from "react";
import "./OnlineStore.css";
import { FaArrowRight } from "react-icons/fa6";
const OnlineStore = () => {
  return (
    <div className="container">
      <div className="OnlineStore">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="OnlineStore_col_para_1">
              <p>
                Increase the presence <br></br>of your online store
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="OnlineStore_col_para_2">
              <p>
                Expand your presence even further than websites and start
                <br></br> selling on marketplaces, on social media, on mobile,
                and more!
              </p>
            </div>
            <div className="OnlineStore_button">
                <button> Get started</button>
                <span>Learn more <FaArrowRight /></span>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineStore;
