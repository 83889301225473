import React from "react";
import "./Templates.css";
import smartkadai from "../../Assests/smartkadai.jpg";
import flowershop from "../../Assests/flowershop.jpg";
import fastfood from "../../Assests/fastfoodshop.jpg";
import medicine from "../../Assests/Medicineshop.jpg";

const Templates = () => {
  return (
    <div className="Templates">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="templates_heading">
              <h1 className="templates_title">
                Solution for <span className="every_color">every</span>
                <span className="business_color"> business</span>
              </h1>
              <p className="templates_subtitle">EXPLORE SOME EXAMPLES</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12 mt-3 mb-5">
            <div className="templates_content">
              <div className="templates_card">
                <div className="templates_card_inner">
                  <img src={smartkadai} alt="smartkadai" />
                </div>
              </div>
              <button className="templates_button">General Merchandise</button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mt-3 mb-5">
            <div className="templates_content">
              <div className="templates_card">
                <div className="templates_card_inner">
                  <img src={flowershop} alt="flowershop" />
                </div>
              </div>
              <button className="templates_button">Flower Shop</button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mt-3 mb-5">
            <div className="templates_content">
              <div className="templates_card">
                <div className="templates_card_inner">
                  <img src={fastfood} alt="fastfood" />
                </div>
              </div>
              <button className="templates_button">Fast Food Shop</button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mt-3 mb-5">
            <div className="templates_content">
              <div className="templates_card">
                <div className="templates_card_inner">
                  <img src={medicine} alt="medicine" />
                </div>
              </div>
              <button className="templates_button">Medicine Shop</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Templates;
