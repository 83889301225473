import React, { useState } from "react";
import "./Home.css";
import hero from "../../Assests/hero-img.png";
import playstore from "../../Assests/playstore2.png";
import appstore from "../../Assests/appstore.png";
import { TypeAnimation } from "react-type-animation";

const Home = () => {
  const [colorIndex, setColorIndex] = useState(0);

  const colors = [
    "color-red",
    "color-green",
    "color-blue",
    "color-pink",
    "color-cyan",
  ];

  const sequences = [
    "ON A WEBSITE",
    "ON FACEBOOK",
    "ON INSTAGRAM",
    "ON TWITTER",
    "ON YOUTUBE",
  ];

  const getColorChangeSequence = () => {
    let colorChangeSequence = [];
    sequences.forEach((text, index) => {
      colorChangeSequence.push(
        text,
        1000,
        () => setColorIndex((prevIndex) => (prevIndex + 1) % colors.length)
      );
    });
    return colorChangeSequence;
  };

  return (
    <>
      <div className="hero_container">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="hero">
                <h1 className="hero_heading">
                  Make your <span style={{ color: "violet" }}>dream </span>
                  store in 5 minutes
                </h1>
              </div>
              <div style={{ fontSize: "3em", fontWeight: "bold" }}>
                START SELLING <br />
                <span className={colors[colorIndex]}>
                  <TypeAnimation
                    sequence={getColorChangeSequence()}
                    wrapper="span"
                    speed={50}
                    repeat={Infinity}
                  />
                </span>
              </div>

              <div className="hero_para">
                <p>
                  <span>
                    Make your dream stores in just 5 minutes with our Platform
                    at{" "}
                  </span>
                  ₹ 199/3 Months
                </p>
              </div>
              <div className="store_images">
                <img src={playstore} alt="playstore" className="playstore" />
                <img src={appstore} alt="appstore" className="appstore" />
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center mt-5">
              <img src={hero} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
