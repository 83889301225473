import React from "react";
import "./Contactus.css";
import { IoCallSharp } from "react-icons/io5";
import { IoMail } from "react-icons/io5";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";

const Contactus = () => {
  return (
    <>
      <div className="contactcontainer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="contact_head">
                <h1>Get a quote</h1>
                <p>
                  Fill up the form and our Team will get back <br /> to you
                  within 24 hours.
                </p>
              </div>
              <div className="mob_email">
                <div>
                  <div className="d-flex align-items-center gap-2 icons_call">
                    <IoCallSharp size={20} className="icon_call" />
                    <span>+91 87785 84566</span>
                  </div>
                  <div className="d-flex align-items-center gap-2 icons_mail">
                    <IoMail size={20} className="icon_call" />
                    <span>contact@pixalive.me</span>
                  </div>
                </div>
              </div>
              <div className="office_address">
                <p className="icon_call">Office Address</p>
                <address>
                  2nd Phase, Third Floor, 35/2, Hosur Rd, <br />
                  Konappana Agrahara, <br />
                  Electronic City, <br />
                  Bengaluru, Karnataka 560100.
                </address>
              </div>
              <div>
                <div className="social_icons">
                  <span>
                    <FaFacebook className="icon_social" />
                  </span>
                  <span>
                    <FaTwitter className="icon_social" />
                  </span>
                  <span>
                    <FaInstagram className="icon_social" />
                  </span>
                  <span>
                    <FaYoutube className="icon_social" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="contact_form">
                <form className="d-flex flex-column">
                  <div className="name_input_box">
                    <label htmlFor="name">Your Name</label>
                    <input type="text" placeholder="Enter Your Name" />
                  </div>
                  <div className="name_input_box">
                    <label htmlFor="email">Your Email</label>
                    <input type="email" placeholder="Enter Your Email" />
                  </div>
                  <div className="name_input_box">
                    <label htmlFor="message">Message</label>
                    <textarea placeholder="Enter Your Message"></textarea>
                  </div>
                  <button type="submit" className="submit_btn_contact">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-center map_row">
            <div className="col-lg-12 col-md-12 col-sm-12 map_column">
              <div className="mapouter">
                <div className="maps_Details">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.9179663074456!2d77.6680843748179!3d12.848574817564112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1589d7163475%3A0xeb85ca7b7efa7262!2sPixalive!5e0!3m2!1sen!2sin!4v1714035606384!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
